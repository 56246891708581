import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Accordion from "./Accordion";
import logo from "../images/logo192.png";
import logogif from "../images/hoag.gif";
import "./About.css";

const Wrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  height: fit-content;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  /* margin-bottom: 100px; */
`;

const TopDiv = styled.div`
  margin-top: 50px;
  display: flex;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
`;

const Logo = styled.img`
  width: 75px;
  height: 75px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LogoGif = styled.img`
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 100px;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: xx-large;
  font-family: 'VT323', monospace;
`;

const Divider = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const AboutText = styled.div`
  display: flex;
  text-align: center;
  width: 50%;
  justify-content: center;
  align-items: center;
`;

export default function About() {
  return (
    <>
      <Wrapper>
        <TopDiv>
          <Logo src={logo}></Logo>
          <TopHeader>What is HOAG?</TopHeader>
        </TopDiv>

        <Divider>
          <AboutText>
            <span>
              HOAG is the place to go when you need to publish your live video content while keeping the ownership. 
              We provide a place where any Munk NFT owner can stream without restrictions, without having to worry about 
              censorship or obscure guidelines of all the big tech companies.
            </span>
          </AboutText>

          <TopHeader>About us</TopHeader>

          <AboutText>
            <span>
              We are a team of crypto enthusiasts that have been working on Solana since 2020, 
              and believe that open-source software like HOAG will be the future of the blockchain. 
              In 2021, we won the <i>Media Network Prize</i> at the Solana Season Hackathon.
            </span>
          </AboutText>
        </Divider>

        <LogoGif src={logogif}></LogoGif>

      </Wrapper>
    </>
  );
}
