import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import AppContext from "../context/context";
import logo from "../images/logo192.png";
import bannerPhoto from "../images/photo1.jpg";
import axios from "axios";
import "./Home.css";
import "./About.css";
import ReactPlayer from "react-player";
import logogif from "../images/hoag.gif";

const HomeWrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 75px;
  height: 75px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 100px;
`;

const LogoGif = styled.img`
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0px;
  padding-top: 0px;
`;

const TopDiv = styled.div`
  margin-top: 50px;
  display: flex;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
  font-family: "VT323", monospace;
`;

const Banner = styled.div`
  display: flex;
  position: relative;
  margin: 0;
  height: 65vh;
  width: 100%;
  background: rgb(182, 33, 254);
  background: linear-gradient(
    90deg,
    rgba(182, 33, 254, 1) 0%,
    rgba(31, 209, 249, 1) 100%
  );
  border-bottom: 0px solid #3c3c3c;
  justify-content: center;
  align-items: center;
`;

const BannerLeft = styled.div`
  display: flex;
  flex: 50%;
  height: 100%;
  /* background-color: antiquewhite; */
  justify-content: center;
  align-items: center;
`;

const LeftTitle = styled.h1`
  letter-spacing: 2px;
  font-family: "VT323", monospace;
  display: flex;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  color: black;
  text-shadow: 1px 1px #000;
`;

const RightTitle = styled.h1`
  letter-spacing: 2px;
  font-family: "VT323", monospace;
  display: flex;
  /* font-size: 100px; */
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: 1px 1px #000;
`;

const BannerRight = styled.div`
  display: flex;
  flex: 50%;
  height: 100%;
  /* background-color: aqua; */
  justify-content: flex-end;
  padding: 20px;
  align-items: center;

  & img {
    max-width: 450px;
    border-radius: 10px;
    opacity: 0.8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderText = styled.img`
  display: flex;
  width: 100px;
  height: 100px;
  font-size: xxx-large;
  font-weight: bolder;
  padding: 10px;
  border-radius: 5px;
`;

const ActiveStream = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "VT323", monospace;
  overflow-x: auto;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: xx-large;
`;

const Streamer = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  background-color: purple;
  border-radius: 25px;
  padding: 20px;
  margin: 6px;
  gap: 2px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const LinkText = styled.span`
  color: white !important;
`;

export default function Home() {
  const { activeStreams, setActiveStreams } = useContext(AppContext);
  const [streamList, setStreamList] = useState([]);

  useEffect(() => {
    let liveStreams = [];

    for (const x in activeStreams) {
      for (const y in activeStreams[x]) {
        liveStreams.push(activeStreams[x][y]);
      }
    }

    setStreamList(liveStreams);
  }, [activeStreams]);

  return (
    <HomeWrapper>
      <TopDiv>
        <TopDiv>
          <LogoGif src={logogif}></LogoGif>
          <TopHeader>Welcome</TopHeader>
          <TopHeader>-------</TopHeader>
        </TopDiv>
      </TopDiv>

      {streamList.length > 0 && (
        <>
          <h1 style={{ fontFamily: "VT323, monospace" }}>Active Streams</h1>
        </>
      )}

      <ActiveStream>
        {streamList.length > 0 ? (
          <>
            {streamList.map((stream) => (
              <>
                <Streamer>
                  <Link to={`/stream/${stream.publisher.stream}`}>
                    <ReactPlayer
                      url={`https://bl9zyqhv.medianetwork.cloud/live/${stream.publisher.stream}/index.m3u8`}
                      className="react-player"
                      width="200px"
                      height="200px"
                      playing={false}
                      controls={false}
                    />
                  </Link>
                  <LinkText>Live Now!</LinkText>
                </Streamer>
              </>
            ))}
          </>
        ) : (
          <>
            <h2>No Active Streams</h2>
          </>
        )}
      </ActiveStream>

      <Logo src={logo} style={{ marginBottom: "30px" }}></Logo>
    </HomeWrapper>
  );
}
