import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import ReactPlayer from "react-player";

const StreamWrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  height: 94vh;
  width: 100%;
  justify-content: center;
  flex-direction: row;
`;

const VideoWrapper = styled.div`
  width: auto;
  height: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const ChatWrapper = styled.div`
  display: flex;
  flex: 20%;
  height: 100%;
  background-color: #18181b;
  flex-direction: column;
  border-left: 1px solid #3c3c3c;
`;

const ChatBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  color: white;
  overflow-y: auto;
`;

const ChatBanner = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: #18181b;
  border-bottom: 0px solid #3c3c3c;
`;

export default function Stream() {
  const { stream } = useParams();

  return (
    <StreamWrapper>
      <VideoWrapper>
        <ReactPlayer
          controls={true}
          url={`https://bl9zyqhv.medianetwork.cloud/live/${stream}/index.m3u8`}
          // url={`http://178.254.31.48:8000/live/test/index.m3u8`}
          className="react-player"
          playing={true}
          width="100%"
          height="100%"
        />
      </VideoWrapper>

      {/* <ChatWrapper>
        <ChatBanner>Stream Chat</ChatBanner>
        <ChatBox></ChatBox>
      </ChatWrapper> */}
    </StreamWrapper>
  );
}
