import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Accordion from "./Accordion";
import logo from "../images/logo192.png";
import "./About.css";
import logogif from "../images/hoag.gif";

const Wrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  height: fit-content;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 50px;
`;

const TopDiv = styled.div`
  margin-top: 50px;
  display: flex;
  height: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  gap: 30px;
`;

const Logo = styled.img`
  width: 75px;
  height: 75px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LogoGif = styled.img`
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 100px;
  padding-bottom: 40px;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: xx-large;
  font-family: 'VT323', monospace;
`;

const TopInput = styled.input`
  display: flex;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 10px;
  background-color: #e3e3e3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const LeftBody = styled.div`
  display: flex;
  height: 90%;
  width: 100%;
  justify-content: center;
  padding: 10px;
  font-weight: bolder;
  color: white;
`;

const QuestionsDiv = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-family: 'VT323', monospace;
`;

export default function FAQ() {
  return (
    <>
      <Wrapper>
        <TopDiv>
          <Logo src={logo}></Logo>
          <TopHeader>How can we help you?</TopHeader>
        </TopDiv>

        <QuestionsDiv>
          <Accordion />
        </QuestionsDiv>

        <LogoGif src={logogif}></LogoGif>

      </Wrapper>
    </>
  );
}
