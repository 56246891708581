import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Stream from "./components/Stream";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import FAQ from "./components/FAQ";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="App">
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/faq"} element={<FAQ />} />
          <Route path={"/stream/:stream"} element={<Stream />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
