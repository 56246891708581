import React, { useReducer, useState, useEffect } from "react";
import AppContext from "./context";
import axios from "axios";

export default function AppState(props) {
  const [activeStreams, setActiveStreams] = useState([]);

  useEffect(() => {
    axios
      .get(`https://play.hoag.network/api/streams`)
      .then((res) => {
        // let liveStreams = [];

        // for (const x in res.data) {
        //   for (const y in res.data[x]) {
        //     liveStreams.push(res.data[x][y]);
        //   }
        // }

        setActiveStreams(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  return (
    <AppContext.Provider
      value={{
        activeStreams,
        setActiveStreams,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
