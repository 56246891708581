import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid #1c1c1c`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#0e0e10",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: "#000000",
  color: "#ffffff",
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
  justifyContent: "center",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography><center>How does it work?</center></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            HOAG let's you stream without having to worry about your content being owned by some big tech company.
            All you need is a MUNK NFT in your wallet, and you'll be ready to use our CDN-based streaming service.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Where can I get the NFT?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can buy the NFT in the different Solana Marketplaces like <a href="https://beta.hadeswap.com/collection/2tfjpcCKrkUN5AM2PKUmVGxqm91r7oDZ8hWihmqKmm2q" target="_blank">Hadeswap</a> or <a href="https://magiceden.io/marketplace/hoag_munk" target="_blank">MagicEden</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What's a CDN?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A CDN (Content Delivery Network) is a network of servers distributed around the world 
            that are used to deliver content, such as web pages, images, and videos, to users. 
            CDNs are designed to improve the speed and reliability of content delivery. 
            CDNs use a variety of techniques to optimize the delivery of content, including caching, compression, and adaptive bitrate streaming.
            HOAG trusts MEDIA Network, the first blockchain-based CDN, to deliver the content you stream.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>How do I get validated?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To validate yourself, simply go into the HOAG Discord and follow the steps.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
