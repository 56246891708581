import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import logo from "../images/hoag.gif";

const Wrapper = styled.div`
  display: flex;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5vh;
  background-color: #18181b;
  border-bottom: 0px solid #3c3c3c;
  padding: 10px 20px 10px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 10;
`;

const NavLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 33%;
  font-weight: bolder;
  font-size: large;
  color: red;
`;

const NavCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 33%;
  gap: 20px;
`;

const NavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 33%;
  gap: 20px;
  font-weight: bolder;
`;

const Logo = styled.img`
  width: 75px;
  height: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default function Navbar() {
  return (
    <Wrapper>
      <Nav>
        <NavLeft>
          <Link to={"/"}>
            <Logo src={logo} />
          </Link>
        </NavLeft>

        <NavCenter></NavCenter>

        <NavRight>
          <Link to={"/about"}>About</Link>

          <Link to={"/faq"}>FAQ</Link>

          <a
            href="https://discord.gg/twDXrDhFV7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
        </NavRight>
      </Nav>
    </Wrapper>
  );
}
